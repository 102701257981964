// ====================================================================================
// font-style: normal;
// ====================================================================================
@font-face {
   font-family: "MMC";
   font-weight: 100;
   src: url('fonts/MMC/MMC-Regular.woff2') format('woff2'),
        url('fonts/MMC/MMC-Regular.woff') format('woff');
}

@font-face {
   font-family: "MMC";
   font-weight: 400;
   src: url('fonts/MMC/MMC-Medium.woff2') format('woff2'),
        url('fonts/MMC/MMC-Medium.woff') format('woff');
}

@font-face {
   font-family: "MMC";
   font-weight: 700;
   src: url('fonts/MMC/MMC-Bold.woff2') format('woff2'),
        url('fonts/MMC/MMC-Bold.woff') format('woff');
}